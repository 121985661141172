import { render, staticRenderFns } from "./NormalizerAlt.vue?vue&type=template&id=f5c8dc4c&scoped=true"
import script from "./NormalizerAlt.vue?vue&type=script&lang=js"
export * from "./NormalizerAlt.vue?vue&type=script&lang=js"
import style0 from "../assets/main.css?vue&type=style&index=0&id=f5c8dc4c&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5c8dc4c",
  null
  
)

export default component.exports