var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-layout',[_c('v-flex',{attrs:{"xs12":""}},[_c('h1',{staticClass:"display-1 mt-10"},[_vm._v("Description Extractor")]),_c('p',[_vm._v(" Generates the HGVS variant description from a reference sequence and an observed sequence. ")]),_c('v-sheet',{staticClass:"mt-10 pa-10",attrs:{"elevation":"2"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":_vm.lazy},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"reference",attrs:{"rules":_vm.rules,"label":'Reference sequence',"hint":'E.g. AATTTCCCGGG',"clearable":true,"autofocus":""},model:{value:(_vm.reference),callback:function ($$v) {_vm.reference=$$v},expression:"reference"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"observed",attrs:{"rules":_vm.rules,"label":'Observed Sequence',"hint":'E.g. AATCCGG',"clearable":true},model:{value:(_vm.observed),callback:function ($$v) {_vm.observed=$$v},expression:"observed"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"example-link",on:{"click":function($event){return _vm.setExample()}}},[_vm._v("Example")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{ref:"extract",attrs:{"color":"primary","disabled":!_vm.valid,"to":{
                  name: 'DescriptionExtractor',
                  query: {
                    reference: _vm.reference,
                    observed: _vm.observed,
                  },
                }}},[_vm._v(" Extract ")])],1)],1)],1)],1),(_vm.summary)?_c('v-sheet',{staticClass:"pa-10 mt-10",attrs:{"elevation":"2"}},[_c('Description',{attrs:{"description":_vm.summary}})],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }