var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.progress_related_references_retriever)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):_vm._e(),(_vm.related_references_retriever)?_c('div',[_vm._l((_vm.related_references_retriever),function(references,source){return _c('div',{key:source},[_c('v-subheader',{staticClass:"overline",attrs:{"inset":""}},[_vm._v(_vm._s(source))]),_vm._l((references),function(reference){return _c('div',{key:reference.selector
            ? reference.id + reference.selector.id
            : reference.id},[(reference.selector)?_c('router-link',{class:'map-link',attrs:{"target":"_blank","to":{
            name: 'Mapper',
            query: {
              description: _vm.description,
              reference_id: reference.id,
              selector_id: reference.selector.id,
              slice_to: 'transcript',
              not_run: true,
            },
          }}},[_vm._v(_vm._s(reference.id + "(" + reference.selector.id + ")"))]):_c('router-link',{class:'map-link',attrs:{"target":"_blank","to":{
            name: 'Mapper',
            query: {
              description: _vm.description,
              reference_id: reference.id,
              not_run: true,
            },
          }}},[_vm._v(_vm._s(reference.id))])],1)})],2)}),_c('v-expansion-panels',{staticClass:"mt-3 mb-3",attrs:{"focusable":"","hover":"","flat":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("View response as a tree")]),_c('v-expansion-panel-content',[_c('JsonPretty',{attrs:{"summary":_vm.related_references_retriever}})],1)],1)],1)],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }