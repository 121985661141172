import { render, staticRenderFns } from "./AffectedProtein.vue?vue&type=template&id=3a108878&scoped=true"
import script from "./AffectedProtein.vue?vue&type=script&lang=js"
export * from "./AffectedProtein.vue?vue&type=script&lang=js"
import style0 from "../assets/main.css?vue&type=style&index=0&id=3a108878&prod&scoped=true&lang=css&external"
import style1 from "./AffectedProtein.vue?vue&type=style&index=1&id=3a108878&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a108878",
  null
  
)

export default component.exports